module.exports = [
  { id: "architect", name: "role-architect", image: 'thumbnails/building-and-roles/Architect.png' },
  { id: "technical-consultant", name: "role-technical-consultant", image: 'thumbnails/building-and-roles/Technical consultant.png' },
  { id: "builder", name: "role-builder", image: 'thumbnails/building-and-roles/Builder.png' },
  { id: "facility-manager", name: "role-facility-manager", image: 'thumbnails/building-and-roles/Facility Manager.png' },
  { id: "building-manager", name: "role-building-manager", image: 'thumbnails/building-and-roles/Building manager.png' },
  { id: "government-and-municipalities", name: "role-government-and-municipalities", image: 'thumbnails/building-and-roles/Government and Municipalities.png' },
  { id: "building-owner", name: "role-building-owner", image: 'thumbnails/building-and-roles/Building Owner.png' },
  { id: "housing-and-owners-cooperation", name: "role-housing-and-owners-cooperation", image: 'thumbnails/building-and-roles/Housing - owners cooperation.png' },
  { id: "tenant", name: "role-tenant", image: 'thumbnails/building-and-roles/Tenant.png' },
  { id: "authorities-and-influencers", name: "role-authorities-and-influencers", image: 'thumbnails/building-and-roles/Authorities and Influencers.png' },
  { id: "sales", name: "role-sales", image: 'thumbnails/building-and-roles/Sales.png' },
  { id: "kone-distributor", name: "role-kone-distributor", image: 'thumbnails/building-and-roles/Kone distributor.png' },
  { id: "other-intrested", name: "role-other-interested", image: 'thumbnails/building-and-roles/Other intrested.png' },

  { id: "architect", name: "role-architect", marine:true, image: 'thumbnails/building-and-roles/Architect.png' },
  { id: "technical-consultant", name: "role-technical-consultant", marine:true, image: 'thumbnails/building-and-roles/Technical consultant.png' },
  { id: "builder", name: "role-builder", marine:true, image: 'thumbnails/building-and-roles/Builder.png' },
  { id: "ship-owner", name: "role-ship-owner", marine:true, image: 'thumbnails/building-and-roles/Facility Manager.png' },
  { id: "ship-yard", name: "role-ship-yard", marine:true, image: 'thumbnails/building-and-roles/ship-yard.png' },
  { id: "government-and-municipalities", name: "role-government-and-municipalities", marine:true, image: 'thumbnails/building-and-roles/Government and Municipalities.png' },
  { id: "ship-designer", name: "role-ship-designer", marine:true, image: 'thumbnails/building-and-roles/Housing - owners cooperation.png' },
  { id: "authorities-and-influencers", name: "role-authorities-and-influencers", marine:true, image: 'thumbnails/building-and-roles/Authorities and Influencers.png' },
  { id: "sales", name: "role-sales", marine:true, image: 'thumbnails/building-and-roles/Sales.png' },
  { id: "kone-distributor", name: "role-kone-distributor", marine:true, image: 'thumbnails/building-and-roles/Kone distributor.png' },
  { id: "other-intrested", name: "role-other-interested", marine:true, image: 'thumbnails/building-and-roles/Other intrested.png' },
]