import * as dat from "dat.gui";
import * as THREE from "three";
import { RENDER_TYPE } from '../store/3d/RenderingManager'

let mainFolder = null;
let callback = null;
let manager = null;
let parentGui = null;

let currentFolders = {}

export function initializePostProcessingGUI (renderingManager, materialManager, renderCallback, gui) {
   
  if (!mainFolder) {
    callback = renderCallback;
    manager = renderingManager;
    parentGui = gui;
    updatePostProcessingEditor();
  }
  return mainFolder;
}

export const updatePostProcessingEditor = () => {
    // mainFolder.add(postProcessingManager, 'renderType', RENDER_TYPE).onChange(function(value){
    //     postProcessingManager.changeRenderType(value);
    //     renderCallback();
    // });
    // createGeneralFolder(postProcessingManager, renderCallback);

    if (!parentGui) return

    const paramNameMapping = {}

    let wasClosed = true;
    if (mainFolder) {
        wasClosed = mainFolder.closed;
        parentGui.removeFolder(mainFolder);
    }
    mainFolder = parentGui.addFolder("Post-processing")
    // console.log('creating postprocessing tools:', manager)
    if (manager.currentComposer.bloomComposer !== undefined){
        currentFolders.bloomFolder = createBloomFolder();
    }

    paramNameMapping.TransparentBackground = manager.transparentBackground
    mainFolder.add(paramNameMapping, 'TransparentBackground').onChange(function(value){
        manager.transparentBackground = value;
        callback();
    });

    for (let index = 0; index < manager.currentComposer.passes.length; index++) {
        const element = manager.currentComposer.passes[index];
        paramNameMapping[element.name] = element.enabled;
        // eslint-disable-next-line no-loop-func
        let func = function(value){
            // manager.transparentBackground = value;
            element.enabled = value;
            callback();
        };
        mainFolder.add(paramNameMapping, element.name).onChange(func);
        if (element.name === 'Taa pass'){
            const sampleName = element.name + ' samples';
            paramNameMapping[sampleName] = element.sampleLevel;
            mainFolder.add(paramNameMapping, sampleName, {
                'Level 0: 1 Sample': 0,
                'Level 1: 2 Samples': 1,
                'Level 2: 4 Samples': 2,
                'Level 3: 8 Samples': 3,
                'Level 4: 16 Samples': 4,
                'Level 5: 32 Samples': 5
                } ).onChange(function(value){
                element.sampleLevel = value;
                callback();
            });

            const accumulateName = 'TAA accumulate';
            paramNameMapping[accumulateName] = element.accumulate;
            mainFolder.add(paramNameMapping, accumulateName).onChange(function(value){
                element.accumulate = value;
                callback();
            });
        } else if (element.name === 'SSAA pass'){
            
            const unbiasName = 'SSAA unbias';
            paramNameMapping[unbiasName] = element.unbiased;
            mainFolder.add(paramNameMapping, unbiasName).onChange(function(value){
                element.unbiased = value;
                callback();
            });
            
        }
    }

    

    paramNameMapping.Render = function() { manager.render(false, true); }
    mainFolder.add(paramNameMapping, 'Render');
    
    paramNameMapping.Snapshot = function() {
        manager.renderImage(function (url) {
            window.open(url);            
        }); 
    }

    paramNameMapping['Raw Snapshot'] = function() {
        const resultUrl = manager.renderDebugSnapshot();
        window.open(resultUrl);
    }
    paramNameMapping['Print pixel buffer'] = function() {
        manager.printDebugPixelArray();
    }
    mainFolder.add(paramNameMapping, 'Snapshot');
    mainFolder.add(paramNameMapping, 'Raw Snapshot');
    mainFolder.add(paramNameMapping, 'Print pixel buffer');

    paramNameMapping.PrintStack = function() {
        const stack = manager.getStack();
        console.groupCollapsed('Current post processing stack:', stack.names)
        console.log(stack.values);
        console.groupEnd();
    }
    mainFolder.add(paramNameMapping, 'PrintStack');

    mainFolder.add(manager, 'dynamicAntialiasing');

    // paramNameMapping.sizeX = manager.lastSize.x
    // paramNameMapping.sizeY = manager.currentComposer.renderer.getSize().y
    // paramNameMapping.pixelRatio = manager.currentComposer.renderer.getSize().y
    // mainFolder.add(manager.lastSize, 'x').onFinishChange(function(value){
    //     const oldY = manager.currentComposer.renderer.getSize().y
    //     manager.setSize(value, oldY, 1)
    //     callback();
    // });
    // mainFolder.add(manager.lastSize, 'y').onFinishChange(function(value){
    //     const oldX = manager.currentComposer.renderer.getSize().x
    //     manager.setSize(value, oldX, 1)
    //     callback()
    // });

    mainFolder.add(manager.lastSize, 'pixelRatio').onFinishChange(function(value){
        const oldX = manager.lastSize.x
        const oldY = manager.lastSize.y
        manager.setSize(oldX, oldY, value)
        callback();
    });


    // createSsaaFolder(postProcessingManager, renderCallback);
    
    // const copyPassOption = mainFolder.add(postProcessingManager.outputPass, 'enabled').onChange(function(value){
    //     postProcessingManager.outputPass.enabled = value;
    //     renderCallback();
    // });
    // copyPassOption.name("Copy pass");
    // mainFolder.add(materialManager.emissionOnly, 'value').onChange(function(value){
    //     // postProcessingManager.setEmissionOnly(value);
    //     materialManager.emissionOnly.value = value
    //     renderCallback();
    // });
  if (!wasClosed){
    mainFolder.open();
  }
};

let generalResolutionTarget =
{ 
    value: 'all'
}

const generalResolutionTargets = {
    all: 'all',
    fxaa: 'fxaa',
    composer: 'composer',
    bloom: 'bloom',
};

const createGeneralFolder = () => {
    const generalFolder = mainFolder.addFolder("General");

    if (!generalResolutionTarget){
        generalResolutionTarget = generalResolutionTargets.all;
    }
    
    generalFolder.add(generalResolutionTarget, 'value', generalResolutionTargets).onChange(function(value){
        generalResolutionTarget.value = value;
    });
    // console.log(postProcessingManager)
    const xController = generalFolder.add(manager.lastSize, "x", 0, 2048).onChange(function(value) {
        manager.setSize(value, manager.lastSize.y, 
            manager.lastSize.pixelRatio, generalResolutionTarget.value, true);
            callback();
    });
    const yController = generalFolder.add(manager.lastSize, "y", 0, 2048).onChange(function(value) {
        manager.setSize(manager.lastSize.x, value, 
            manager.lastSize.pixelRatio, generalResolutionTarget.value, true);
            callback();
    });
    const pixelRatioController = generalFolder.add(manager.lastSize, "pixelRatio", 0, 10).onChange(function(value) {
        manager.setSize(manager.lastSize.x, 
            manager.lastSize.y, value,  generalResolutionTarget.value, true);
            callback();
    });

    xController.listen()
    yController.listen()
    pixelRatioController.listen()
    
    currentFolders['general'] = generalFolder;
}

const createSsaaFolder = (postProcessingManager, renderCallback) => {
    const ssaaFolder = mainFolder.addFolder("SSAA");
    // console.log(postProcessingManager)

    ssaaFolder.add(postProcessingManager.ssaaPass, "enabled").onChange(function(value) {
        postProcessingManager.ssaaPass.enabled = value;
        renderCallback();
    });

    ssaaFolder.add(postProcessingManager.ssaaPass, "unbiased").onChange(function(value) {
        postProcessingManager.ssaaPass.unbiased = value;
        renderCallback();
    });
    
    ssaaFolder.add(postProcessingManager.ssaaPass, "sampleLevel", {
        'Level 0: 1 Sample': 0,
        'Level 1: 2 Samples': 1,
        'Level 2: 4 Samples': 2,
        'Level 3: 8 Samples': 3,
        'Level 4: 16 Samples': 4,
        'Level 5: 32 Samples': 5
    }).onChange(function(value) {
        postProcessingManager.ssaaPass.sampleLevel = value;
        renderCallback();
    });
    currentFolders['ssaaFolder'] = ssaaFolder;
}


const createBloomFolder = () => {

    const bloomFolder = mainFolder.addFolder("Bloom");
    // console.log(postProcessingManager)
    const bloomPass = manager.currentComposer.bloomComposer.bloomPass;
    bloomFolder.add(bloomPass, "enabled").onChange(function(value) {
        bloomPass.enabled = value;
        callback();
    });
    bloomFolder.add(bloomPass, "strength", 0, 2).onChange(function (value) {
        bloomPass.strength = value;
        callback();
    });
    bloomFolder.add(bloomPass, "threshold", 0, 1).onChange(function (value) {
        bloomPass.threshold = value;
        callback();
    });
    bloomFolder.add(bloomPass, "radius", 0, 2).onChange(function (value) {
        bloomPass.radius = value;
        callback();
    });
    return bloomFolder;
}

const createClearPassFolder = () => {

    const clearFolder = mainFolder.addFolder("Clear pass");
    // console.log(postProcessingManager)
    const clearPass = manager.currentComposer.clearPass;

    const paramNameMapping = {}
    
    paramNameMapping.ClearPass = clearPass.enabled
    clearFolder.add(paramNameMapping, "ClearPass").onChange(function (value) {
        clearPass.enabled = value;
        callback();
    });
    paramNameMapping.ClearColor = clearPass.clearColor
    clearFolder.addColor(paramNameMapping, "ClearColor").onChange(function (value) {
        // value
        // handleColorChange(clearPass.clearAlpha);
        console.log(clearPass)
        clearPass.clearColor = value;
        callback();
    });
    paramNameMapping.ClearAlpha = clearPass.clearAlpha
    clearFolder.add(paramNameMapping, "ClearAlpha", 0, 1, 0.01).onChange(function (value) {
        clearPass.clearAlpha = value;
        console.log(clearPass)
        callback();
    });
    return clearFolder;
}

export default initializePostProcessingGUI;
